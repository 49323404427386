.main-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  margin: auto;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid rgb(180, 21, 140);
  border-bottom: 16px solid rgb(180, 21, 140);
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.header-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 2%;
  margin-left: 6%;
}

.show-balances {
  display: flex;
  font-weight: 600;
  flex-direction: column;
  font-size: 20px;
  padding-left: 8%;
  padding-top: 0.5%;
  color: #ffffff;
  text-align: left;
}

.reset-button {
  font-weight: 800;
  font-size: large;
  margin-left: 2%;
  padding-top: 0.5%;
  padding-bottom: 1%;
  background: #ffffff;
  color: #982c96;
  height: 20px;
  width: 100px;
  border: 1px solid #982c96;
  border-radius: 35px;
  opacity: 1;
  margin-top: 0.5%;
  cursor: pointer;
}

.claim-all-button {
  display: flex;
  background: #ffffff;
  color: #982c96;
  height: 60px;
  width: 260px;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #982c96;
  border-radius: 35px;
  opacity: 1;
  margin-left: 4%;
  cursor: pointer;
  justify-content: center;
}

.unstake-button-disabled {
  display: flex;
  flex-direction: row;
  background: #8a8d8d;
  color: #ffffff;
  height: 40px;
  width: 200px;
  border: 1px solid #ffffff;
  border-radius: 35px;
  opacity: 1;
  margin-left: 18%;
  margin-top: 1%;
}

.unstake-button-disabled > span {
  font-weight: 800;
  font-size: large;
  margin: auto;
}

.claim-all-button > span {
  font-weight: 700;
  font-size: large;
  margin: auto;
}

.wallet-button {
  display: flex;
  background: #982c96;
  color: #ffffff;
  height: 60px;
  width: 260px;
  border: 1px solid #ffffff;
  border-radius: 35px;
  opacity: 1;
  margin-left: 2%;
}

.birthday-bonus {
  display: flex;
  background: #6a868a;
  color: #ffffff;
  height: 30px;
  width: 260px;
  border: 1px solid #ffffff;
  border-radius: 35px;
  opacity: 1;
  cursor: pointer;
  margin-right: 0;
  margin-left: auto;
}

.birthday-bonus > span {
  font-weight: 700;
  font-size: large;
  margin: auto;
}

.wallet-button-details {
  display: flex;
  flex-direction: column;
  font-weight: 700;
  padding: auto;
  margin: auto;
  overflow: hidden;
}

.main-cards-container {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  margin-top: 1%;
}

.card-container {
  width: 90vw;
  height: auto;
  min-height: 70vh;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  font: normal normal medium 30px/39px Roboto;
  margin-left: 5%;
  margin-bottom: 2%;
}

.switch-tabs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-left: 12%;
  font-size: large;
  margin-right: 6%;
}

.switch-tabs-active {
  padding-bottom: 1%;
  cursor: pointer;
  color: #e26ce0;
  border-bottom: 4px solid #e26ce0;
  font-weight: 800;
  font-size: 24px;
  margin-right: 4%;
}

.switch-tabs-inactive {
  padding-bottom: 1%;
  cursor: pointer;
  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
  margin-right: 4%;
}

.lock-button {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-left: 60%;
}

.checkbox-active-lock {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 45%;
}

.lock-all {
  font-weight: 800;
  font-size: small;
  padding-top: 3%;
  padding-left: 1%;
  padding-right: 1%;
  margin-right: 12%;
  background: #982c96;
  color: #ffffff;
  height: 30px;
  width: 220px;
  border: 1px solid #ffffff;
  border-radius: 35px;
  opacity: 1;
  margin-top: 2%;
  cursor: pointer;
  margin-left: auto;
  margin-right: 8%;
}

.buttons {
  display: flex;
  flex-direction: row;
}

.green-state {
  font-weight: 800;
  font-size: small;
  padding-top: 5%;
  padding-left: 1%;
  padding-right: 1%;
  margin-right: 12%;
  background: #982c96;
  color: #ffffff;
  height: 30px;
  width: 220px;
  border: 1px solid #ffffff;
  border-radius: 35px;
  opacity: 1;
  margin-top: 3%;
  cursor: pointer;
  margin-left: auto;
  margin-right: 8%;
}

.select-all-button {
  margin-left: 4%;
  padding-top: 1%;
}

.grey-state {
  font-weight: 800;
  font-size: small;
  padding-top: 5%;
  padding-left: 1%;
  padding-right: 1%;
  margin-right: 12%;
  background: #8a8d8d;
  color: #ffffff;
  height: 30px;
  width: 220px;
  border: 1px solid #ffffff;
  border-radius: 35px;
  opacity: 1;
  margin-top: 3%;
  margin-right: 8%;
}

.lock-multiple {
  font-weight: 800;
  font-size: large;
  padding-top: 2%;
  background: #ffffff;
  color: #982c96;
  height: 40px;
  width: 250px;
  border: 1px solid #982c96;
  border-radius: 35px;
  opacity: 1;
  margin-top: 2%;
  cursor: pointer;
}

.cards-list {
  display: flex;
  flex-direction: row;
  margin: 1% 4% 4% 4%;
  overflow-x: auto;
}
.approve {
  font-weight: 800;
  font-size: 24px;
  padding: 2%;
  background: #982c96;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 35px;
  opacity: 1;
  cursor: pointer;
  margin-top: 20%;

  margin-right: 25%;
  margin-left: 25%;
}

.approve-text {
  font-weight: 800;
  font-size: 20px;
  color: #ffffff;
}
