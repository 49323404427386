.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: transparent url("../public/Images/app/app-background.png") 0% 0%
    no-repeat padding-box;
  background-repeat: no-repeat;
  background-size: cover;
  font: normal normal medium 30px/39px LATO;
  width: 100vw;
  height: 120vh;
  margin: auto;
}

footer {
  font: normal normal medium 30px/39px LATO;
  background-color: #ffffff;
  color: #982c96;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 700;
  height: 102px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #982c96;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #982c96;
}
