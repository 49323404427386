.loginPage {
  display: flex;
  height: 120vh;
}

.login-modal {
  display: flex;
  flex-direction: column;
  width: 30vw;
  height: 40vh;
  box-shadow: inset 0 0 100px rgba(247, 244, 244, 0.815);
  border-radius: 8px;
  font: normal normal medium 30px/39px Roboto;
  margin-top: 14%;
  margin-left: 34%;
}

.wallet-connection {
  background: #982c96;
  color: #ffffff;
  box-shadow: 0px 3px 6px #ffffff;
  font-weight: 600;
  height: 40px;
  width: 200px;
  border: 2px solid #ffffff;
  border-radius: 35px;
  opacity: 1;
  padding-top: 3%;
  margin: auto;
  cursor: pointer;
  font-size: large;
}
