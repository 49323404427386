.each-card-details {
  display: flex;
  flex-direction: column;
  border: 1px solid #ffffff;
  margin: 5px;
  padding: 2%;
  color: #ffffff;
  border-radius: 10px;
}
.each-card-details-locked {
  display: flex;
  flex-direction: column;
  background: #c4b383;
  margin: 5px;
  padding: 2%;
  color: #ffffff;
}
.unstake-button {
  display: flex;
  flex-direction: row;
  background: #982c96;
  color: #ffffff;
  height: 40px;
  width: 200px;
  border: 1px solid #ffffff;
  border-radius: 35px;
  opacity: 1;
  cursor: pointer;
  margin-left: 18%;
  margin-top: 1%;
}
.unstake-button-disabled {
  display: flex;
  flex-direction: row;
  background: #8a8d8d;
  color: #ffffff;
  height: 40px;
  width: 200px;
  border: 1px solid #ffffff;
  border-radius: 35px;
  opacity: 1;
  margin-left: 18%;
  margin-top: 1%;
}
.unstake-button-disabled > span {
  font-weight: 800;
  font-size: large;
  margin: auto;
  padding: auto;
}

.unstake-button > span {
  font-weight: 800;
  font-size: large;
  margin: auto;
  padding: auto;
}

.display-name {
  font-weight: 800;
  font-size: 24px;
  padding: 1%;
}

.display-price {
  font-weight: 600;
  font-size: 16px;
  padding: 1%;
  color: rgb(210, 210, 221);
  text-decoration: underline;
  margin-bottom: 2%;
  cursor: pointer;
}

.link {
  font-weight: 600;
  font-size: 12px;
  padding: 1%;
  cursor: pointer;
  color: #982c96;
}

.check-box {
  padding-bottom: 2%;
  margin-left: auto;
  margin-right: 0;
}
